import type { LinguiConfig } from '@lingui/conf'
import { formatter } from '@lingui/format-po'

export const locales = [
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-LU',
  'el-GR',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-NZ',
  'en-US',
  'es-AR',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-ES',
  'es-MX',
  'et-EE',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'he-IL',
  'hr-HR',
  'hu-HU',
  'is-IS',
  'it-CH',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'lb-LU',
  'lt-LT',
  'lv-LV',
  'nb-NO',
  'nl-BE',
  'nl-NL',
  'nn-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'rm-CH',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'sv-SE',
  'tr-TR',
]

const config: LinguiConfig = {
  format: formatter({ lineNumbers: false }),
  locales: locales,
  catalogs: [
    {
      path: '<rootDir>/src/locales/{locale}',
      include: ['src/routes', 'src/utils', 'src/i18n'],
      exclude: ['src/pb', 'src/components'],
    },
  ],
  fallbackLocales: {
    default: 'en-US',
  },
  sourceLocale: 'en-US',
  orderBy: 'origin',
}

export default config
