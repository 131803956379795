import { dynamicActivate } from '@/i18n.ts'
import type { Transport } from '@connectrpc/connect'
import { i18n } from '@lingui/core'
import { detect, fromNavigator } from '@lingui/detect-locale'
import { I18nProvider } from '@lingui/react'
import type { QueryClient } from '@tanstack/react-query'
import { Outlet, ScrollRestoration, createRootRouteWithContext } from '@tanstack/react-router'
import React, { Suspense, useEffect } from 'react'
import { Toaster } from 'sonner'

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : React.lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )

const ReactQueryDevtools = import.meta.env.PROD
  ? () => null
  : React.lazy(() =>
      import('@tanstack/react-query-devtools').then((res) => ({
        default: res.ReactQueryDevtools,
      })),
    )

function RootComponent() {
  useEffect(() => {
    const fallbackLocale = 'en-US'
    const locale = detect(fromNavigator()) ?? fallbackLocale
    dynamicActivate(locale)
  }, [])
  return (
    <I18nProvider i18n={i18n}>
      <Outlet />
      <ScrollRestoration />
      <Toaster />
      <Suspense>
        <TanStackRouterDevtools />
        <ReactQueryDevtools />
      </Suspense>
    </I18nProvider>
  )
}

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
  connectTransport: Transport
}>()({
  component: RootComponent,
})
