import { i18n } from '@lingui/core'

export const fallbackLocales: Record<string, string> = {
  en: 'en-US',
  es: 'es-ES',
  de: 'de-DE',
  fr: 'fr-FR',
  pt: 'pt-PT',
  bg: 'bg-BG',
  cs: 'cs-CZ',
  da: 'da-DK',
  el: 'el-GR',
  et: 'et-EE',
  fi: 'fi-FI',
  he: 'he-IL',
  hr: 'hr-HR',
  hu: 'hu-HU',
  is: 'is-IS',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  lb: 'lb-LU',
  lt: 'lt-LT',
  lv: 'lv-LV',
  nb: 'nb-NO',
  nl: 'nl-NL',
  nn: 'nn-NO',
  pl: 'pl-PL',
  rm: 'rm-CH',
  ro: 'ro-RO',
  ru: 'ru-RU',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sv: 'sv-SE',
  tr: 'tr-TR',
}

export async function dynamicActivate(locale: string) {
  try {
    const { messages } = await import(`./locales/${locale}.po`)
    i18n.load(locale, messages)
    i18n.activate(locale)
  } catch (e) {
    const [generalLang] = locale.split('-')
    let fallbackLocale = 'en-US'

    if (generalLang in fallbackLocales) {
      fallbackLocale = fallbackLocales[generalLang]
    }

    const { messages } = await import(`./locales/${fallbackLocale}.po`)
    i18n.load(fallbackLocale, messages)
    i18n.activate(fallbackLocale)
  }
}
